import React, { useRef, useState, useEffect } from 'react';
import './formulario.css';
import API from "../../providers/api";
import { Row, Col, Card, CardBody, Input, Button, UncontrolledCollapse, Spinner } from "reactstrap"
import { FaArrowAltCircleRight, FaTrashAlt, FaFileSignature, FaPhotoVideo, FaArrowAltCircleLeft, FaSave, FaSyncAlt } from 'react-icons/fa';
import SignatureCanvas from 'react-signature-canvas'
import logo from '../../assets/img/logo.jpg'
import logoFooter from '../../assets/img/logo-footer.png'
import success from '../../assets/img/success.png'
import fail from '../../assets/img/fail.png'
import warning from '../../assets/img/warning.png'
import { alert } from '../../helpers/alert';
import moment from 'moment'
import CanvasFile from "../CanvasFile";
import Webcam from 'react-webcam'
import imagenFoto from '../../assets/img/logo-footer.png'
import imgEnglish from '../../assets/img/english.jpg'
import imgSpanish from '../../assets/img/spanish.jpg'

const Formulario = () => {

    const WebcamComponent = () => <Webcam />
    const videoConstraints = {
        width: 400,
        height: 225,
        facingMode: 'user',
    }

    const [picture, setPicture] = useState('')
    const webcamRef = React.useRef(null)
    const capture = React.useCallback(() => {
        const pictureSrc = webcamRef.current.getScreenshot()
        setPicture(pictureSrc)
    })

    const [agencyID, setAgencyID] = useState('')
    const [viewForm, setViewForm] = useState(false)
    const [viewInputs, setViewInputs] = useState(true)
    const [viewSign, setViewSign] = useState(true)
    const [applicant, setApplicant] = useState('')
    const [applicantSignature, setApplicantSignature] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [validateFormatEmail, setValidateFormatEmail] = useState(null)
    const [agent, setAgent] = useState('')
    const [sign, setSign] = useState('')
    const [signA, setSignA] = useState('')
    const [url, setUrl] = useState('')
    const [urlA, setUrlA] = useState('')
    const [revoke, setRevoke] = useState('-')
    const [agentName, setAgentName] = useState('-')
    const [agentNumber, setAgentNumber] = useState('-')
    const [agentPhone, setAgentPhone] = useState('-')
    const [agentEmail, setAgentEmail] = useState('-')
    const [agencyName, setAgencyName] = useState('-')
    const [agencyNumber, setAgencyNumber] = useState('-')
    const [agencyOwner, setAgencyOwner] = useState('-')
    const [agencyPhone, setAgencyPhone] = useState('-')
    const [agencyAddress, setAgencyAddress] = useState('-')
    const [loadingSave, setLoadingSave] = useState(false)
    const [resultSuccess, setResultSuccess] = useState(true)
    const [loadingInit, setLoadingInit] = useState(false)
    const [formSend, setFormSend] = useState(false)
    const [redirect, setRedirect] = useState(true)
    const [typeSignature, setTypeSignature] = useState(2)
    const [font, setFont] = useState('Arial')
    const [count, setCount] = useState(0)
    const [emailReceives, setEmailReceives] = useState('');
    const [token, setToken] = useState('');
    const [dateExpiration, setDateExpiration] = useState(moment().add(1, 'year').format('MM/DD/YYYY'))
    //const arrayFonts = ['Sacramento','Tangerine','WindSong','Italianno','Qwigley','Petemoss','Gwendolyn','Waterfall','Licorice','Stalemate']
    const arrayFonts = ['Arial','stalemate']

    const [canvasMaker, setCanvasMaker] = useState(null);
    const [validateSignature, setValidateSignature] = useState(false);
    const [btnValSignature, setBtnValSignature] = useState(true);
    const [btnGetSignature, setBtnGetSignature] = useState(false);
    const [btnCamare, setBtnCamare] = useState(false);
    const [btnSave, setBtnSave] = useState(false);
    const [canvasArea, setCanvasArea] = useState(true);

    const [divSpanish, setDivSpanish] = useState(false);
    const [divEnglish, setDivEnglish] = useState(false);
    const [language, setLanguage] = useState('');

    const viewButtonSign =  false
    let canvasVal = null;
    let contextVal = null;
    const canvasSurface = useRef(null)
    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const token = queryParameters.get("token")
        setToken(token)
        API.validateForm(token)
        .then(data => data.json())
        .then(dataJson => {
            if (dataJson.success == true) {
                const info = dataJson.data
                setApplicant(info.name)
                setPhone(info.phone)
                setEmail(info.email)
                setAgentName(info.agentname)
                setAgentNumber(info.npn)
                setAgentPhone(info.agentphone)
                setAgentEmail(info.agentemail)
                setEmailReceives(info.email_receives)
                setViewForm(true)
                setFormSend(true)
                canvasVal = canvasSurface.current;
                setCanvasMaker(canvasVal)
                styleCanvas(letterCapital(info.name))
                canvasVal.width=canvasVal.width
            }else{
                setViewForm(false)
                setDivEnglish(false)
                setDivSpanish(false)
                setLoadingInit(true)
                if(dataJson.status == 'processed')
                    setResultSuccess(true)
                else
                    setResultSuccess(false)
                setFormSend(false)
                return alert('Warning!', dataJson.message, 'warning', 'Closed');
            }
        })
        .catch((e) => {
            console.log("Error =>", e)
        })
    }, [])

    const styleCanvas = (name) => {
        if(canvasVal != null)
        {
            contextVal = canvasVal.getContext("2d");
            contextVal.fillStyle = "#000";
            contextVal.font = "50px Stalemate";
            contextVal.fillText(name, 40, 50);
            setSignA(canvasVal)
        }else if(canvasMaker != null){
            contextVal = canvasMaker.getContext("2d");
            contextVal.fillStyle = "#000";
            contextVal.font = "50px Stalemate";
            contextVal.fillText(name, 40, 50);
            setSignA(canvasMaker)
        }
    }

    const styleTryCanvas = () => {
        setValidateSignature(true)
        canvasMaker.width=canvasMaker.width
        styleCanvas(applicantSignature)
        setBtnValSignature(false)
        setBtnGetSignature(true)
        setBtnCamare(false)
        setBtnSave(false)
    }

    const handleClear = () => {
        sign.clear()
        setUrl('')
    }

    const handleGenerate = () => {
        setUrl(sign.getTrimmedCanvas().toDataURL('image/png'))
        setUrlA('')
    }

    const handleGenerateA = () => {
        if(!validateSignature)
            alert('Warning!', 'Validate your signature.', 'warning', 'Closed');
        setUrl('')
        //console.log(signA.toDataURL('image/png'));
        setUrlA(signA.toDataURL('image/png'))
        setBtnValSignature(false)
        setBtnGetSignature(false)
        setBtnCamare(true)
        setCanvasArea(false)
        setBtnSave(true)
    }

    const handleSign = () => {
        setViewSign(!viewSign)
    }

    const handleSave = () => {
        if(!validateSignature)
            return alert('Warning!', 'Validate your signature.', 'warning', 'Closed');
        if(revoke == ""){
            return alert('Warning!', 'Select method to revoke consent.', 'warning', 'Closed');
        }
        if(applicant == ""){
            return alert('Warning!', 'Empty Full name.', 'warning', 'Closed');
        }
        if(phone == ""){
            return alert('Warning!', 'Empty Phone.', 'warning', 'Closed');
        }
        if(email == ""){
            return alert('Warning!', 'Empty Email.', 'warning', 'Closed');
        }
        if(url == "" && urlA == ""){
            return alert('Warning!', 'Empty signature.', 'warning', 'Closed');
        }
        setLoadingSave(true)
        API.saveForm(token, url != "" ? url : urlA != "" ? urlA : '', applicant, phone, email, agentName, agentNumber, agentPhone, agentEmail, agencyName, agencyNumber, agencyOwner, agencyPhone, agencyAddress, emailReceives, picture != "" ? picture : '-', language)
            .then(data => data.json())
            .then(dataJson => {
                if (dataJson.success == true) {
                    setViewForm(false)
                    setDivEnglish(false)
                    setDivSpanish(false)
                    alert('Success!', dataJson.message, 'success', 'Closed');
                }else{
                    alert('Warning!', dataJson.message, 'warning', 'Closed');
                }
                setLoadingSave(false)
            })
            .catch((e) => {
                console.log("Error =>", e)
            })
    }

    const onchangeTypeSignature = (e) => {
        if(applicant == "")
            return alert('Warning!', 'Empty Full name.', 'warning', 'Closed');
        const value = e.target.value
        setTypeSignature(value)
        if(value == "1"){
            setUrl('')
        }
        letterCapital(applicant)
    }

    const changeFont = () => {
        if(count != arrayFonts.length){
            setCount(count+1)
        }else{
            setCount(0)
        }
        console.log(arrayFonts[count != arrayFonts.length ? count : 0]);
        setFont(arrayFonts[count != arrayFonts.length ? count : 0])
        //setFont(arrayFonts[0])
    }

    const validateEmail = (email) => {
        let isValidEmail = /\S+@\S+\.\S+/.test(email);
        if (!isValidEmail) {
            setValidateFormatEmail(false);
        }else{
            setValidateFormatEmail(true);
        }
    }

    const onchangeApplicant = (e) => {
        setApplicant(e.target.value.toUpperCase())
    }

    const letterCapital = (nombre) => {
        let nombreSpace = nombre.trim()
        const expRegular = /(\s{2,})/g;
        let remplazar = nombreSpace.replace(expRegular,' ');

        console.log(remplazar, "remplazar");
        const palabras = remplazar.toLowerCase().split(" ");

        setApplicantSignature(palabras.map((palabra) => {
            return palabra[0].toUpperCase() + palabra.substring(1);
        }).join(" "));

    }

    const onClickLenguaje = (lenguaje) =>{
        if(lenguaje == 'english'){
            setDivEnglish(true)
            setDivSpanish(false)
            setLanguage('en')
        }else{
            setDivEnglish(false)
            setDivSpanish(true)
            setLanguage('es')
        }
    }

    return (
        <div className='flex-container'>
            <div>
                <Card style={{ opacity: '0.9' }}>
                    <div className='row'>
                        <div className='col-lg-1'></div>
                        <div className='col-lg-10 text-center'>
                            <a href="#">
                                <img style={{width:'100%',maxWidth:'300px'}} src={logo} className='logoWidth' />
                            </a>
                        </div>
                        <div className='col-lg-1'></div>
                    </div>
                    {
                        viewForm ?
                            divEnglish ?
                                <CardBody>
                                    <div className='row mt-4'>
                                        <div className='col-lg-1'></div>
                                        <div className='col-lg-8'></div>
                                        <div className='col-lg-3'><b>Expiration Date:</b> {dateExpiration}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-1'></div>
                                        <div className='col-lg-10'>
                                            <b>Legal Disclosure:</b>
                                            <p style={{fontStyle:'italic',textAlign:'justify'}}>
                                            The contents of this document do not have the force and effect of law and are not meant to bind the public in
                                            any way, unless specifically incorporated into a contract. This document is intended only to provide clarity to the
                                            public regarding existing requirements under the law. This model consent form will not supersede any State
                                            Agent of Record, Broker of Record, or other form required by a QHP issuer for purposes of making commission
                                            payments to the proper agent or broker for assisting a particular consumer.
                                            </p>
                                            <b>Purpose Statement:</b>
                                            <p style={{textAlign:'justify'}}>
                                            Registered agents and brokers assisting consumers apply for and enroll in Marketplace coverage must document
                                            consumer consent prior to accessing or updating their Marketplace information. CMS does not prescribe the
                                            manner in which agents and brokers must document consent. Instead, there are different formats that may be
                                            acceptable for agents and brokers to use to document consumer consent, such as via a recorded phone call, text
                                            message, email, electronic document with digital signatures, physical document with wet signatures, etc. This
                                            model consent form serves as an example for how agents and brokers may document consent via a physical
                                            document with wet signatures.
                                            </p>
                                            <p style={{textAlign:'justify'}}>
                                            Since this model consent form is a best practice for obtaining consumer consent, you may tailor the form to
                                            address the needs of your specific business model in addition to meeting the CMS requirement to document
                                            consent from a consumer prior to assisting the consumer enroll in coverage in the Marketplace, including prior
                                            to conducting a person search. For example, if an Agency is involved, you may clarify specifically who else within
                                            the Agency other than the writing Agent is able to view and use the consumer’s PII to assist the writing Agent in
                                            enrolling the consumer in Marketplace coverage for compliance, commissions, or other relevant purposes as
                                            you see fit.
                                            </p>
                                        </div>
                                        <div className='col-lg-1'></div>
                                    </div>
                                    {/* <div className='row'>
                                        <div className='col-lg-1'></div>
                                        <div className='col-lg-10 text-bold'>
                                            <p>
                                                Please, complete the following fields.
                                            </p>
                                        </div>
                                        <div className='col-lg-1'></div>
                                    </div> */}
                                    <div className='row'>
                                        <div className='col-lg-1'></div>
                                        <div className='col-lg-10'>
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    <label className='text-bold' style={applicant === "" ? { color: "red" } : {}}>Full Name {applicant === "" ? "*" : ""}</label>
                                                    <Input value={applicant} onChange={e => onchangeApplicant(e)} disabled />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <label className='text-bold' style={phone === "" ? { color: "red" } : {}}>Phone {phone === "" ? "*" : ""}</label>
                                                    <Input value={phone} onChange={event => {
                                                                let numbers = event.target.value.replace(/[^0-9]/g, "")
                                                                if (numbers.length > 10) {
                                                                    let sliceSsn = phone.slice(0, 10)
                                                                    setPhone(sliceSsn);
                                                                } else {
                                                                    setPhone(numbers);
                                                                }
                                                            }} disabled />
                                                </div>
                                                <div className='col-lg-6'>
                                                    <label className='text-bold' style={email === "" ? { color: "red" } : {}}>Email {email === "" ? "*" : ""}</label>
                                                    {
                                                        (validateFormatEmail === false && email !== "") &&
                                                        <label style={{ color: "red" }}>, format is invalid</label>
                                                    }
                                                    <Input value={email} onChange={event => {
                                                                validateEmail(event.target.value.toUpperCase());
                                                                setEmail(event.target.value.toUpperCase())
                                                            }} disabled />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-1'></div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-lg-1'></div>
                                        <div className='col-lg-10 text-center'>
                                            <b>CMS Model Consent Form for Marketplace Agents and Brokers</b>
                                        </div>
                                        <div className='col-lg-1'></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-1'></div>
                                        <div className='col-lg-10'>
                                            <p style={{textAlign:'justify'}}>
                                            I, <b style={{textDecoration:'underline'}}>{applicant}</b>, give my permission to <b style={{textDecoration:'underline'}}>{agentName}</b> to serve as the
                                            health insurance agent or broker for myself and my entire household if applicable, for purposes of enrollment in
                                            a Qualified Health Plan offered on the Federally Facilitated Marketplace. By consenting to this agreement, I
                                            authorize the above-mentioned Agent to view and use the confidential information provided by me in writing,
                                            electronically, or by telephone only for the purposes of one or more of the following:
                                            </p>
                                            <p style={{textAlign:'justify'}}>
                                                <ol>
                                                    <li>Searching for an existing Marketplace application;</li>
                                                    <li>Completing an application for eligibility and enrollment in a Marketplace Qualified Health Plan or other
                                                        government insurance affordability programs, such as Medicaid and CHIP or advance tax credits to help
                                                        pay for Marketplace premiums;</li>
                                                    <li>Providing ongoing account maintenance and enrollment assistance, as necessary; or</li>
                                                    <li>Responding to inquiries from the Marketplace regarding my Marketplace application.</li>
                                                </ol>
                                            </p>
                                            <p style={{textAlign:'justify'}}>
                                            I understand that the Agent will not use or share my personally identifiable information (PII) for any purposes
                                            other than those listed above. The Agent will ensure that my PII is kept private and safe when collecting, storing,
                                            and using my PII for the stated purposes above.
                                            </p>
                                            <p style={{textAlign:'justify'}}>
                                            I confirm that the information I provide for entry on my Marketplace eligibility and enrollment application will
                                            be true to the best of my knowledge. I understand that I do not have to share additional personal information
                                            about myself or my health with my Agent beyond what is required on the application for eligibility and
                                            enrollment purposes. I understand that my consent remains in effect until I revoke it, and I may revoke or
                                            modify my consent at any time by <b style={{textDecoration:'underline'}}>{applicant}</b> {/* <Input type='select' value={revoke} onChange={(e) => setRevoke(e.target.value)}>
                                                    <option>Select method to revoke consent</option>
                                                    <option value="Method One">Method One</option>
                                                </Input> */}
                                            </p>
                                        </div>
                                        <div className='col-lg-1'></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-1'></div>
                                        <div className='col-lg-10'>
                                            <div>
                                            <table style={{width:'100%'}}>
                                                <tr>
                                                    <td>Name of Primary Writing Agent:</td><td style={{fontWeight:'bold'}}>{agentName}</td>
                                                </tr>
                                                <tr>
                                                    <td>Agent National Producer Number: </td><td style={{fontWeight:'bold'}}>{agentNumber}</td>
                                                </tr>
                                                <tr>
                                                    <td>Phone Number: </td><td style={{fontWeight:'bold'}}>{agentPhone}</td>
                                                </tr>
                                                <tr>
                                                    <td>Email Address: </td><td style={{fontWeight:'bold'}}>{agentEmail}</td>
                                                </tr>
                                            </table>
                                            </div>
                                            <div>
                                            <table style={{width:'100%'}}>
                                                <tr>
                                                    <td>Name of Primary Household Contact<br/>
                                                    and/or Authorized Representative:  </td><td style={{fontWeight:'bold'}}>{applicant}</td>
                                                </tr>
                                                <tr>
                                                    <td>Phone Number: </td><td style={{fontWeight:'bold'}}>{phone}</td>
                                                </tr>
                                                <tr>
                                                    <td>Email Address: </td><td style={{fontWeight:'bold'}}>{email}</td>
                                                </tr>
                                                <tr>
                                                    <td>Date: </td><td style={{fontWeight:'bold'}}>{moment().format('MM-DD-YYYY')}</td>
                                                </tr>
                                                <tr>
                                                    <td>Signature:  </td><td>
                                                        <img src={url != "" ? url : urlA != "" ? urlA : ''} style={{width:'100%'}} />
                                                    </td>
                                                </tr>
                                            </table>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-1'></div>
                                                <div className='col-lg-10 text-center'>
                                                    {
                                                        viewButtonSign ?
                                                            <button className='btn btn-primary' onClick={handleSign}>Signature</button>
                                                        : ''
                                                    }
                                                </div>
                                                <div className='col-lg-1'></div>
                                            </div>
                                            <br/>
                                            <div className='row' style={{display:'none'}}>
                                                <div className='col-lg-2'></div>
                                                <div className='col-lg-8 text-center'>
                                                    <label>Select type signature</label>
                                                    <select className='form-control' value={typeSignature} onChange={e => onchangeTypeSignature(e)}>
                                                        <option value="">Select</option>
                                                        <option value="1">Manuscript</option>
                                                        <option value="2">Automatic</option>
                                                    </select>
                                                </div>
                                                <div className='col-lg-2'></div>
                                            </div>
                                            <br/>
                                        </div>
                                        <div className='col-lg-1'></div>
                                    </div>
                                </CardBody>
                            : divSpanish ?
                                <CardBody>
                                    <div className='row mt-4'>
                                        <div className='col-lg-1'></div>
                                        <div className='col-lg-8'></div>
                                        <div className='col-lg-3'><b>Fecha de expiración:</b> {dateExpiration}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-1'></div>
                                        <div className='col-lg-10'>
                                            <b>Legal Divulgación:</b>
                                            <p style={{fontStyle:'italic',textAlign:'justify'}}>
                                            El contenido de este documento no tiene fuerza ni efecto de ley y no pretende obligar al público en cualquier
                                            forma, a menos que específicamente incorporado en a contrato. Este documento es destinado solo para
                                            proporcionar claridad a el público sobre los requisitos existentes en la ley. Este modelo de formulario de
                                            consentimiento no reemplazará a ningún Estado Agente registrado, corredor registrado u otro formulario
                                            requerido por un emisor de QHP para fines de generar comisiones pagos a el adecuado agente o corredor para
                                            ayudando a un particular consumidor.
                                            </p>
                                            <b>Objetivo Declaración:</b>
                                            <p style={{textAlign:'justify'}}>
                                            Registrado agentes y corredores ayudando consumidores aplicar para y inscribirse en Mercado cobertura debe
                                            documentoconsentimiento del consumidor antes de acceder o actualizar su información del Mercado. CMS no
                                            prescribe el manera en que los agentes y corredores deben documentar el consentimiento. En cambio, existen
                                            diferentes formatos que pueden ser aceptable para que los agentes y corredores lo utilicen para documentar el
                                            consentimiento del consumidor, como a través de una llamada telefónica grabada, mensaje de textomensaje,
                                            correo electrónico, documento electrónico con firma digital, documento físico con firma húmeda, etc. Este El
                                            formulario de consentimiento modelo sirve como ejemplo de cómo los agentes y corredores pueden
                                            documentar el consentimiento a través de un documento físico. documento con húmedo firmas.
                                            </p>
                                            <p style={{textAlign:'justify'}}>
                                            Dado que este modelo de formulario de consentimiento es una mejor práctica para obtener el consentimiento
                                            del consumidor, puede adaptar el formulario a abordar las necesidades de su modelo de negocio específico
                                            además de cumplir con el requisito de CMS de documentar consentimiento de un consumidor antes de ayudar
                                            al consumidor a inscribirse en la cobertura del Mercado, incluido el consentimiento previo a realizando una
                                            persona buscar. Por ejemplo, si un Agencia es involucrado, tú puede aclarar específicamente OMS demás dentro
                                            la Agencia que no sea el Agente que escribe puede ver y utilizar la PII del consumidor para ayudar al Agente que
                                            escribe en inscribir al consumidor en la cobertura del Mercado para cumplimiento, comisiones u otros fines
                                            relevantes como tú ver adaptar.
                                            </p>
                                        </div>
                                        <div className='col-lg-1'></div>
                                    </div>
                                    {/* <div className='row'>
                                        <div className='col-lg-1'></div>
                                        <div className='col-lg-10 text-bold'>
                                            <p>
                                                Please, complete the following fields.
                                            </p>
                                        </div>
                                        <div className='col-lg-1'></div>
                                    </div> */}
                                    <div className='row'>
                                        <div className='col-lg-1'></div>
                                        <div className='col-lg-10'>
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    <label className='text-bold' style={applicant === "" ? { color: "red" } : {}}>Nombre completo {applicant === "" ? "*" : ""}</label>
                                                    <Input value={applicant} onChange={e => onchangeApplicant(e)} disabled />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <label className='text-bold' style={phone === "" ? { color: "red" } : {}}>Teléfono {phone === "" ? "*" : ""}</label>
                                                    <Input value={phone} onChange={event => {
                                                                let numbers = event.target.value.replace(/[^0-9]/g, "")
                                                                if (numbers.length > 10) {
                                                                    let sliceSsn = phone.slice(0, 10)
                                                                    setPhone(sliceSsn);
                                                                } else {
                                                                    setPhone(numbers);
                                                                }
                                                            }} disabled  />
                                                </div>
                                                <div className='col-lg-6'>
                                                    <label className='text-bold' style={email === "" ? { color: "red" } : {}}>Correo electrónico {email === "" ? "*" : ""}</label>
                                                    {
                                                        (validateFormatEmail === false && email !== "") &&
                                                        <label style={{ color: "red" }}>, format is invalid</label>
                                                    }
                                                    <Input value={email} onChange={event => {
                                                                validateEmail(event.target.value.toUpperCase());
                                                                setEmail(event.target.value.toUpperCase())
                                                            }} disabled />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-1'></div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-lg-1'></div>
                                        <div className='col-lg-10 text-center'>
                                            <b>CMS Modelo Consentir Forma para Mercado Agentes y Corredores</b>
                                        </div>
                                        <div className='col-lg-1'></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-1'></div>
                                        <div className='col-lg-10'>
                                            <p style={{textAlign:'justify'}}>
                                            Yo, <b style={{textDecoration:'underline'}}>{applicant}</b>, dar mi permiso a <b style={{textDecoration:'underline'}}>{agentName}</b> para actuar como agente o corredor de seguros médicos para mí y para todo mi hogar, si
                                            corresponde, para fines de inscripción enun plan de salud calificado ofrecido en el Mercado facilitado a nivel
                                            federal. Al dar mi consentimiento a este acuerdo, yo Autorizo al Agente antes mencionado a ver y utilizar la
                                            información confidencial proporcionada por mí por escrito, electrónicamente, o por solo telefono para el
                                            propósitos de uno o más de el siguiente:
                                            </p>
                                            <p style={{textAlign:'justify'}}>
                                                <ol>
                                                    <li>Buscando para un existente Mercado solicitud;</li>
                                                    <li>Completar una solicitud de elegibilidad e inscripción en un Plan de Salud Calificado del Mercado u otro
                                                        programas gubernamentales de asequibilidad de seguros, como Medicaid y CHIP, o créditos fiscales
                                                        anticipados para ayudarpagar para Mercado primas;</li>
                                                    <li>Proporcionar en curso cuenta mantenimiento y inscripción asistencia, según sea necesario; o</li>
                                                    <li>Respondiendo a consultas de el Mercado acerca de mi Mercado solicitud.</li>
                                                </ol>
                                            </p>
                                            <p style={{textAlign:'justify'}}>
                                            Entiendo que el Agente no utilizará ni compartirá mi información de identificación personal (PII) para ningún
                                            propósito. otro que aquellos listado arriba. El Agente voluntad asegurar eso mi información personal es conservó
                                            privado y seguro cuando coleccionar, almacenar, y usando mi PII para el fijado propósitos anteriores.
                                            </p>
                                            <p style={{textAlign:'justify'}}>
                                            Confirmo que la información que proporciono para ingresar en mi solicitud de elegibilidad e inscripción en el
                                            Mercadoser fiel a lo mejor de mi conocimiento. Entiendo que no tengo que compartir información personal
                                            adicional sobre mí o mi salud con mi Agente más allá de lo requerido en la solicitud de elegibilidad y fines de
                                            inscripción. Entiendo que mi consentimiento permanece vigente hasta que lo revoque, y puedo revocarlo o
                                            modificar mi consentir en cualquier tiempo por <b style={{textDecoration:'underline'}}>{applicant}</b> {/* <Input type='select' value={revoke} onChange={(e) => setRevoke(e.target.value)}>
                                                    <option>Select method to revoke consent</option>
                                                    <option value="Method One">Method One</option>
                                                </Input> */}
                                            </p>
                                        </div>
                                        <div className='col-lg-1'></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-1'></div>
                                        <div className='col-lg-10'>
                                            <div>
                                            <table style={{width:'100%'}}>
                                                <tr>
                                                    <td>Nombre de Primario Escribiendo Agente:</td><td style={{fontWeight:'bold'}}>{agentName}</td>
                                                </tr>
                                                <tr>
                                                    <td>Agente Nacional Productor Número: </td><td style={{fontWeight:'bold'}}>{agentNumber}</td>
                                                </tr>
                                                <tr>
                                                    <td>Teléfono Número: </td><td style={{fontWeight:'bold'}}>{agentPhone}</td>
                                                </tr>
                                                <tr>
                                                    <td>Correo electrónico DIRECCIÓN: </td><td style={{fontWeight:'bold'}}>{agentEmail}</td>
                                                </tr>
                                            </table>
                                            </div>
                                            <div>
                                            <table style={{width:'100%'}}>
                                                <tr>
                                                    <td>Nombre de Primario Familiar<br/>
                                                    Contactoy/o Autorizado:  </td><td style={{fontWeight:'bold'}}>{applicant}</td>
                                                </tr>
                                                <tr>
                                                    <td>Representante Teléfono Número:  </td><td style={{fontWeight:'bold'}}>{phone}</td>
                                                </tr>
                                                <tr>
                                                    <td>Correo electrónico DIRECCIÓN: </td><td style={{fontWeight:'bold'}}>{email}</td>
                                                </tr>
                                                <tr>
                                                    <td>Fecha: </td><td style={{fontWeight:'bold'}}>{moment().format('MM-DD-YYYY')}</td>
                                                </tr>
                                                <tr>
                                                    <td>Firma:  </td><td>
                                                        <img src={url != "" ? url : urlA != "" ? urlA : ''} style={{width:'100%'}} />
                                                    </td>
                                                </tr>
                                            </table>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-1'></div>
                                                <div className='col-lg-10 text-center'>
                                                    {
                                                        viewButtonSign ?
                                                            <button className='btn btn-primary' onClick={handleSign}>Firma</button>
                                                        : ''
                                                    }
                                                </div>
                                                <div className='col-lg-1'></div>
                                            </div>
                                            <br/>
                                        </div>
                                        <div className='col-lg-1'></div>
                                    </div>
                                </CardBody>
                            :
                                <>
                                    <Row className='mt-3'>
                                        <Col lg="4"></Col>
                                        <Col lg="4" className='text-center text-bold'>Select a language </Col>
                                        <Col lg="4"></Col>
                                    </Row>
                                    <Row>
                                        <Col lg="2"></Col>
                                        <Col lg="4">
                                            <br/>
                                            <a href="javascript:void(0)" onClick={() => onClickLenguaje('english')}>
                                                <img src={imgEnglish} style={{width:'100%'}} />
                                            </a>
                                        </Col>
                                        <Col lg="4">
                                            <br/>
                                            <a href="javascript:void(0)" onClick={() => onClickLenguaje('spanish')}>
                                                <img src={imgSpanish} style={{width:'100%'}} />
                                            </a>
                                        </Col>
                                        <Col lg="2"></Col>
                                    </Row>
                                </>
                        :<div className='row mt-4'>
                            <div className='col-lg-1'></div>
                            <div className='col-lg-10 text-center'>
                                {
                                    loadingInit ?
                                        resultSuccess ?
                                            <p style={{fontSize:'14pt',fontWeight:'bold'}}><img src={warning} style={{width:'20%'}} /><br/>This information has already been processed</p>
                                        : <p style={{fontSize:'14pt',fontWeight:'bold'}}><img src={fail} style={{width:'20%'}} /><br/>No was possible the processing</p>
                                    : formSend ?
                                            <p style={{fontSize:'14pt',fontWeight:'bold'}}><img src={success} style={{width:'20%'}} /><br/>Data proccessed successfully.</p>
                                        : <Spinner color="success" />
                                }
                            </div>
                            <div className='col-lg-1'></div>
                        </div>
                    }
                    <div className='row'>
                        <div className='col-lg-1'></div>
                        <div className='col-lg-10 text-center'>
                            { canvasArea ? <canvas width='600' ref={canvasSurface} height='100'></canvas>:''}
                        </div>
                        <div className='col-lg-1'></div>
                    </div>
                    <br/>
                    {
                        divEnglish ?
                            <CardBody>
                                <div className='row'>
                                    <div className='col-lg-1'></div>
                                    <div className='col-lg-10 text-center'>
                                        {
                                            btnValSignature ?
                                            <>
                                                <label style={{fontWeight:'bold'}}>Step 1: </label><br/>
                                                <button className='btn btn-primary' onClick={styleTryCanvas}> <FaFileSignature size={15} /> Validate Signature</button>
                                            </> : ''
                                        }
                                    </div>
                                    <div className='col-lg-1'></div>
                                </div>
                                <br/>
                                <div className='row'>
                                    <div className='col-lg-1'></div>
                                    <div className='col-lg-10 text-center'>
                                        {
                                            btnGetSignature ?
                                            <>
                                                <label style={{fontWeight:'bold'}}>Step 2: </label><br/>
                                                <button className='btn btn-danger' onClick={handleGenerateA}><FaFileSignature size={15} /> Get Signature</button>
                                            </> : ''}
                                    </div>
                                    <div className='col-lg-1'></div>
                                </div>
                                {
                                    btnCamare ?
                                        <Row>
                                            <Col lg="3"></Col>
                                            <Col lg="6">
                                                <label style={{fontWeight:'bold'}}>Step 3: My photo</label>
                                                {picture == '' ? (
                                                    <Webcam
                                                        audio={false}
                                                        height={225}
                                                        ref={webcamRef}
                                                        width="100%"
                                                        screenshotFormat="image/jpeg"
                                                        videoConstraints={videoConstraints}
                                                    />
                                                    ) : (
                                                    <img src={picture} style={{width:'100%'}} />
                                                )}
                                                {picture != '' ? (
                                                    <button
                                                        onClick={(e) => {
                                                        e.preventDefault()
                                                        setPicture('')
                                                        }}
                                                        className="btn btn-primary"
                                                    >
                                                        Try again <FaSyncAlt size={15} />
                                                    </button>
                                                    ) : (
                                                    <button
                                                        onClick={(e) => {
                                                        e.preventDefault()
                                                        capture()
                                                        }}
                                                        className="btn btn-danger"
                                                    >
                                                        Get photo <FaPhotoVideo size={20} />
                                                    </button>
                                                )}
                                            </Col>
                                            <Col lg="3"></Col>
                                        </Row>
                                    : ''
                                }
                                <div className='row mt-4'>
                                    <div className='col-lg-1'></div>
                                    <div className='col-lg-10 text-center'>
                                    {
                                        loadingSave ?
                                            <><Spinner color='success' /><br/><b style={{color:'#198564'}}>Send data... Please wait.</b></>
                                            : btnSave ?
                                                <>
                                                    <label style={{fontWeight:'bold'}}>Step 4: </label><br/>
                                                    <button className='btn btn-success' onClick={handleSave}><FaSave size={15} /> Save</button>
                                                </> : ''
                                    }
                                    </div>
                                    <div className='col-lg-1'></div>
                                </div>
                                <p className='mt-4' style={{fontSize:'9pt',textAlign:'justify'}}><b>PRA DISCLOSURE: </b>  According to the Paperwork Reduction Act of 1995, no persons are required to respond to a collection of information unless it displays a
                                valid OMB control number. Expiration date is {dateExpiration}. The time required
                                to complete this information collection is estimated to take up to 0.08 hours per applicant per year, including the time to review instructions, gather the
                                information needed, and complete and review the information collection. If you have comments concerning the accuracy of the time estimate(s) or
                                suggestions for improving this form, please write to: CMS, 7500 Security Boulevard, Attn: PRA Reports Clearance Officer, Mail Stop C4-26-05, Baltimore,
                                Maryland 21244-1850. ****CMS Disclosure**** Please do not send applications, claims, payments, medical records or any documents containing
                                sensitive information to the PRA Reports Clearance Office. Please note that any correspondence not pertaining to the information collection burden
                                approved under the associated OMB control number listed on this form will not be reviewed, forwarded, or retained. If you have questions or concerns
                                regarding where to submit your documents, please contact Brian Gubin at Brian.Gubin@cms.hhs.gov.</p>
                            </CardBody>
                        : divSpanish ?
                            <CardBody>
                                <div className='row'>
                                    <div className='col-lg-1'></div>
                                    <div className='col-lg-10 text-center'>
                                        {
                                            btnValSignature ?
                                            <>
                                                <label style={{fontWeight:'bold'}}>Paso 1: </label><br/>
                                                <button className='btn btn-primary' onClick={styleTryCanvas}> <FaFileSignature size={15} /> Validar firma</button>
                                            </> : ''
                                        }
                                    </div>
                                    <div className='col-lg-1'></div>
                                </div>
                                <br/>
                                <div className='row'>
                                    <div className='col-lg-1'></div>
                                    <div className='col-lg-10 text-center'>
                                        {
                                            btnGetSignature ?
                                            <>
                                                <label style={{fontWeight:'bold'}}>Paso 2: </label><br/>
                                                <button className='btn btn-danger' onClick={handleGenerateA}><FaFileSignature size={15} /> Obtener firma</button>
                                            </> : ''}
                                    </div>
                                    <div className='col-lg-1'></div>
                                </div>
                                {
                                    btnCamare ?
                                        <Row>
                                            <Col lg="3"></Col>
                                            <Col lg="6">
                                                <label style={{fontWeight:'bold'}}>Paso 3: Mi foto</label>
                                                {picture == '' ? (
                                                    <Webcam
                                                        audio={false}
                                                        height={225}
                                                        ref={webcamRef}
                                                        width="100%"
                                                        screenshotFormat="image/jpeg"
                                                        videoConstraints={videoConstraints}
                                                    />
                                                    ) : (
                                                    <img src={picture} style={{width:'100%'}} />
                                                )}
                                                {picture != '' ? (
                                                    <button
                                                        onClick={(e) => {
                                                        e.preventDefault()
                                                        setPicture('')
                                                        }}
                                                        className="btn btn-primary"
                                                    >
                                                        Intentar nuevamente <FaSyncAlt size={15} />
                                                    </button>
                                                    ) : (
                                                    <button
                                                        onClick={(e) => {
                                                        e.preventDefault()
                                                        capture()
                                                        }}
                                                        className="btn btn-danger"
                                                    >
                                                        Obtener foto <FaPhotoVideo size={20} />
                                                    </button>
                                                )}
                                            </Col>
                                            <Col lg="3"></Col>
                                        </Row>
                                    : ''
                                }
                                <div className='row mt-4'>
                                    <div className='col-lg-1'></div>
                                    <div className='col-lg-10 text-center'>
                                    {
                                        loadingSave ?
                                            <><Spinner color='success' /><br/><b style={{color:'#198564'}}>Enviando datos... Por favor espere</b></>
                                            : btnSave ?
                                                <>
                                                    <label style={{fontWeight:'bold'}}>Paso 4: </label><br/>
                                                    <button className='btn btn-success' onClick={handleSave}><FaSave size={15} /> Guardar</button>
                                                </> : ''
                                    }
                                    </div>
                                    <div className='col-lg-1'></div>
                                </div>
                                <p className='mt-4' style={{fontSize:'9pt',textAlign:'justify'}}><b>PRA DIVULGACIÓN: </b>  De acuerdo a a el Papeleo Reducción Acto de 1995, No las personas son requerido a responder a a recopilación de información a
                                menos que él muestra a número de control OMB válido. la fecha de
                                vencimiento es {dateExpiration}. El tiempo requerido Se estima que completar esta recopilación de información tomará hasta 0,08 horas por solicitante por
                                año, incluido el tiempo para revisar las instrucciones, recopilar la información necesaria y completar y revisar la recopilación de información. Si tiene comentarios sobre la exactitud de la(s) estimación(es) de tiempo o sugerencias para mejorar este formulario, escriba a: CMS, 7500 Security Boulevard, a la
                                atención de: PRA Reports Clearance Officer, Mail Stop C4-26-05, Baltimore, Maryland 21244-1850. ****CMS Divulgación**** Por favor no enviar
                                aplicaciones, reclamos, pagos, registros médicos o cualquier documento que contiene información confidencial a la Oficina de autorización de informes de
                                PRA. Tenga en cuenta que cualquier correspondencia que no pertenezca a la carga de recopilación de información aprobado bajo el número de control
                                asociado de la OMB que figura en este formulario no será revisado, reenviado ni retenido. Si tienes preguntas o inquietudes acerca de a donde entregar su
                                documentos, por favor contacto brian Gubín en Brian.Gubin@cms.hhs.gov.</p>
                            </CardBody>
                        : ''
                    }
                    <div className='row'>
                        <div className='col-lg-12 text-center'>
                        Powered by <br/><label className='text-bold'>TICSign - OBAMACARE</label>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
}

export default Formulario;